<template>
    <div class="animate__animated animate__fadeInUp">
        <v-card-title>{{$t("support.rules.title")}}</v-card-title>
        <v-card-text>
            <div v-html="$t('support.rules.foreword')" class="mb-5"></div>
            <span v-for="index in $variables.v.amount_rules" :key="index">{{index}}. {{$t("support.rules.rule.rule-" + (index))}}<br></span>
            <br><br>
            {{$t("support.rules.thank-you")}}
        </v-card-text>
    </div>
</template>


<script>
    export default {
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-rules')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>